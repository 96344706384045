import axios from '@/axios';

const RESOURCE_NAME = '/admin/trackers';

export default {

    getSharedTrackers(data) {
        return axios.post(`${RESOURCE_NAME}/get-shared-trackers`, data)
    },

    getUsers(id) {
        return axios.get(`${RESOURCE_NAME}/get-users?id=${id}`)
    },
}
